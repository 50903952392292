var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rust-and-sensor _flex _font-weight__600"},[_c('div',{staticClass:"rust-box"},[_vm._m(0),_c('div',{staticClass:"rust-box__content"},[_c('div',{staticClass:"rust-box__content__text",attrs:{"data-label":"Rust detected"}},[_vm._v(" "+_vm._s(_vm.resolution(_vm.detail, 'rustDetectedCount'))+" ")]),_c('el-button',{attrs:{"type":"info"},on:{"click":function($event){return _vm.$router.push({
            path: '/analysis',
            query: {
              tabsIndex: 0,
              time: _vm.time
            }
          })}}},[_vm._v("Show More")])],1)]),_c('div',{staticClass:"tilt-sensor"},[_c('div',{staticClass:"title-box"},[_vm._m(1),_c('el-button',{attrs:{"type":"info"},on:{"click":function($event){return _vm.$router.push({ path: '/data', query: { tabsIndex: 0, time: _vm.time } })}}},[_vm._v(" Show More ")])],1),_c('div',{staticClass:"tilt-sensor__content"},_vm._l((_vm.sersors),function(item){return _c('div',{key:item.axle,staticClass:"tilt-sensor__content__item _font-size__heading2 _flex _flex-justify__between"},[_c('span',{staticClass:"tilt-sensor__item__axle",attrs:{"data-axle":item.axle}},[_vm._v("R")]),_c('span',[_vm._v(_vm._s(_vm.resolution(_vm.detail, 'latelyTiltSensorData.' + item.key))+"°")])])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-box"},[_c('div',{staticClass:"title-box__icon"},[_c('img',{attrs:{"src":require("../../../assets/icons/dashboard/Rust.png")}}),_c('span',[_vm._v("Rust")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-box__icon"},[_c('img',{attrs:{"src":require("../../../assets/icons/dashboard/Tilt Sensor.png")}}),_c('span',[_vm._v("Tilt Sensor")])])
}]

export { render, staticRenderFns }